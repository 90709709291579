<div *ngIf="!next" class="choose-documents">
  <ng-container *ngIf="documents; else loading">
    <div class="choose-documents__body">
      <div class="choose-documents__header">
        <h3>Choose Documents you need</h3>
        <div class="search">
          <input [(ngModel)]="filter" placeholder="Search" type="text"/>
        </div>
        <p
          *ngIf="typeOfApplication === AdmissionTypeEnum.Admission ||
                 typeOfApplication === AdmissionTypeEnum.SupervisorReport">
          Please select package:
        </p>
        <div *ngIf="typeOfApplication === AdmissionTypeEnum.SupervisorReport" class="choose-documents__list-item">
          <div class="form-group">
            <input
              (change)="selectAll(AdmissionTypeEnum.SupervisorReport)"
              [checked]="allSelectedSR"
              [disabled]="uncheckSRPackage"
              id="doc-select-all_SR"
              type="checkbox"/>
            <label for="doc-select-all_SR">
              <span class="document-name">Supervisory Visit Package</span>
            </label>
          </div>
        </div>
        <div
          *ngIf="typeOfApplication === AdmissionTypeEnum.Admission ||
               typeOfApplication === AdmissionTypeEnum.SupervisorReport && withAdmission"
          class="choose-documents__list-item">
          <div class="form-group">
            <input
              (change)="selectAll(AdmissionTypeEnum.Admission)"
              [checked]="allSelectedAdmission"
              [disabled]="uncheckAdmissionPackage"
              id="doc-select-all_Admission"
              type="checkbox"/>
            <label for="doc-select-all_Admission">
              <span class="document-name">Start of Care Package</span>
            </label>
          </div>
        </div>
      </div>
      <ng-container *ngIf="typeOfApplication === AdmissionTypeEnum.SupervisorReport">
        <p>Please select a separate Supervisory Visit document:</p>
        <ul
          [class.full]="typeOfApplication === AdmissionTypeEnum.SupervisorReport && withAdmission"
          class="choose-documents__list pb0">
          <ng-container *ngIf="documents.length; else noItems">
            <ng-container *ngFor="let doc of documents">
              <ng-container *ngIf="doc.type === 'supervisory_report'">
                <li *ngIf="showDoc(doc.title)" class="choose-documents__list-item">
                  <div class="form-group">
                    <input
                      (change)="toggle(doc)"
                      [checked]="doc.checked"
                      [disabled]="isDisabledChange(doc)"
                      [id]="'doc' + doc.type + doc.key"
                      type="checkbox"/>
                    <label [for]="'doc' + doc.type + doc.key">
                      <span class="document-name">{{ doc.title }}</span>
                    </label>
                  </div>
                </li>
              </ng-container>
            </ng-container>
          </ng-container>
        </ul>
      </ng-container>
      <ng-container *ngIf="!(typeOfApplication === AdmissionTypeEnum.SupervisorReport && !withAdmission)">
        <p
          *ngIf="typeOfApplication === AdmissionTypeEnum.Admission ||
               typeOfApplication === AdmissionTypeEnum.SupervisorReport &&
               withAdmission">
          Please select a separate Start of Care document:
        </p>
        <p
          *ngIf="typeOfApplication === AdmissionTypeEnum.DischargeSummary ||
                  typeOfApplication === AdmissionTypeEnum.AdditionalDocuments ||
                  typeOfApplication === AdmissionTypeEnum.AdditionalDocumentsInt ||
                 typeOfApplication === AdmissionTypeEnum.POC">
          Please select document:
        </p>
        <ul class="choose-documents__list">
          <ng-container *ngIf="documents.length; else noItems">
            <ng-container *ngFor="let doc of documents">
              <ng-container *ngIf="doc.type === 'admission' || doc.type === 'discharge' || doc.type === 'poc' ">
                <li *ngIf="showDoc(doc.title)" class="choose-documents__list-item">
                  <div class="form-group">
                    <input
                      (change)="toggle(doc)"
                      [checked]="doc.checked"
                      [disabled]="isDisabledChange(doc)"
                      [id]="'doc' + doc.type + doc.key"
                      type="checkbox"/>
                    <label [for]="'doc' + doc.type + doc.key">
                      <span class="document-name">{{ doc.title }}</span>
                    </label>
                  </div>
                </li>
              </ng-container>
            </ng-container>
          </ng-container>
        </ul>
      </ng-container>
    </div>
    <div *ngIf="documents?.length" class="next-btn-wrap">
      <button
        (click)="toggleStep()"
        [disabled]="!selectedDocs.length"
        class="btn violet next-btn"
        data-e2e="choose-documents-proceed">
        Proceed
      </button>
    </div>
    <ng-template #noItems>
      <div class="message">No documents found</div>
    </ng-template>
  </ng-container>
</div>

<div *ngIf="next" class="choose-documents-action">
  <div class="back-btn-wrapper">
    <span (click)="toggleStep()"><i class="icon-back-arrow"></i> Back to documents</span>
  </div>
  <ul class="choose-documents-action__list">
    <li *ngFor="let doc of selectedDocs">{{ doc.title }}</li>
  </ul>
  <div class="choose-documents-action__buttons">
    <h3>Send for sign by:</h3>
    <ul class="choose-documents-action__buttons-list">
      <li>
        <ng-container
          *appPermission="isPOC ? [PermissionAdmissionEnum.CreatePOC] : [PermissionAdmissionEnum.SendToSign]">
          <button
            (click)="chooseAction('email')"
            *ngIf="typeOfApplication !== AdmissionTypeEnum.DischargeSummary"
            [class.active]="activeEmailField"
            [disabled]="disableAllButtons"
            class="text">
            <i class="icon-mail"></i> Email
          </button>
        </ng-container>
      </li>
      <li>
        <ng-container
          *appPermission="isPOC ? [PermissionAdmissionEnum.CreatePOC] : [PermissionAdmissionEnum.SendToSign]">
          <button
            (click)="chooseAction('phone')"
            *ngIf="typeOfApplication !== AdmissionTypeEnum.DischargeSummary"
            [class.active]="activePhoneField"
            [disabled]="disableAllButtons"
            class="text">
            <i class="icon-file-text1"></i> Text
          </button>
        </ng-container>
      </li>
      <li
        *appPermission="[PermissionAdmissionEnum.Create, PermissionAdmissionEnum.Update,  PermissionAdmissionEnum.CreatePOC]">
        <button
          (click)="activePhoneField = false; chooseAction('sign')"
          [disabled]="!created || disableAllButtons"
          data-e2e="choose-documents-admission-sign">
          <i class="icon-signature"></i> Sign
        </button>
      </li>
    </ul>
  </div>

  <div *ngIf=" activeEmailField && typeOfApplication === AdmissionTypeEnum.POC" [formGroup]="emailFormGroup"
       class="choose-form">
    <div class="choose-form__list">
      <div *ngIf="(currentCaregiver$ | async).isSupervisor || !hasSignedAdmission" class="choose-form__item">
        <span>Supervisor*</span>
        <input
          (input)="disabledActions(false); removeError('supervisorEmail')"
          appMarkInvalid
          formControlName="supervisorEmail"
          type="text"/>
        <span class="err-msg"></span>
      </div>
      <div *ngIf="!hasSignedAdmission" class="choose-form__item">
        <span>Patient*</span>
        <input
          (input)="disabledActions(false); removeError('patientEmail')"
          appMarkInvalid
          formControlName="patientEmail"
          type="text"/>
      </div>
      <div
        *ngFor="let hha of (emailFormGroup.controls['hhaEmails'] | formControl).controls; let i=index"
        class="choose-form__item">
        <span>DCW{{ ((currentCaregiver$ | async).isEmployee || !hasSignedAdmission) ? '*' : '' }}</span>
        <div class="choose-form__row">
          <div class="field">
            <input
              (input)="disabledActions(false); removeError(errorSend['hhaEmails']?.[i])"
              [class.error]="errorSend['hhaEmails.'+i]"
              [formControl]="(hha | formControl)"
              appMarkInvalid
              type="text"/>
          </div>
          <button (click)="removeHHA('Email', i)" *ngIf="i > 0">
            <svg
              class="choose-form__delete"
              fill="none"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg">
              <path
                clip-rule="evenodd"
                d="M10 1.93548C5.54609 1.93548 1.93548 5.54609 1.93548 10C1.93548 14.4539 5.54609 18.0645 10 18.0645C14.4539 18.0645 18.0645 14.4539 18.0645 10C18.0645 5.54609 14.4539 1.93548 10 1.93548ZM0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10ZM13.2379 6.76183C13.6158 7.13976 13.6158 7.7525 13.2379 8.13042L11.3686 9.99972L13.2387 11.8699C13.6167 12.2478 13.6167 12.8605 13.2387 13.2385C12.8608 13.6164 12.2481 13.6164 11.8701 13.2385L10 11.3683L8.12847 13.2398C7.75054 13.6178 7.1378 13.6178 6.75988 13.2398C6.38195 12.8619 6.38195 12.2492 6.75988 11.8712L8.6314 9.99972L6.76072 8.12904C6.3828 7.75111 6.3828 7.13837 6.76072 6.76045C7.13865 6.38252 7.75139 6.38252 8.12932 6.76045L10 8.63113L11.8693 6.76183C12.2472 6.3839 12.86 6.3839 13.2379 6.76183Z"
                fill-rule="evenodd"/>
            </svg>
          </button>
        </div>
      </div>
    </div>
    <div class="choose-form__buttons">
      <button (click)="addHHA('Email')"
              *ngIf="(emailFormGroup.controls['hhaEmails'] | formControl).controls.length < 20"
              class="add-button">
        <i class="icon-plus"></i> Add one more DCW
      </button>
    </div>
    <div class="">
      <button (click)="sendDocs('email', true)" [disabled]="!created || !emailFormGroup.valid || isDisableSendAction"
              class="btn violet">
        Send
      </button>
    </div>

  </div>

  <div *ngIf=" activePhoneField && typeOfApplication === AdmissionTypeEnum.POC" [formGroup]="phoneFormGroup"
       class="choose-form">
    <div class="choose-form__list">
      <div *ngIf="(currentCaregiver$ | async).isSupervisor || !hasSignedAdmission" class="choose-form__item">
        <span>Supervisor*</span>
        <input
          (input)="disabledActions(false); removeError('supervisorPhone')"
          [mask]="phoneMask"
          appMarkInvalid
          appMask
          formControlName="supervisorPhone"
          placeholder="+1 (000) 000-0000"
          type="tel"/>
      </div>
      <div *ngIf="!hasSignedAdmission" class="choose-form__item">
        <span>Patient*</span>
        <input
          (input)="disabledActions(false); removeError('patientPhone')"
          [mask]="phoneMask"
          appMarkInvalid
          appMask
          formControlName="patientPhone"
          placeholder="+1 (000) 000-0000"
          type="tel"/>
      </div>
      <div
        *ngFor="let hha of (phoneFormGroup.controls['hhaPhones'] | formControl).controls; let i=index"
        class="choose-form__item">
        <span>DCW{{ (!(currentCaregiver$ | async).isSupervisor || !hasSignedAdmission) ? '*' : '' }}</span>
        <div class="choose-form__row">
          <div class="field">
            <input
              (input)="disabledActions(false); removeError(errorSend['hhaPhones']?.[i])"
              [formControl]="(hha | formControl)"
              [mask]="phoneMask"
              appMarkInvalid
              appMask
              placeholder="+1 (000) 000-0000"
              type="tel"/>
          </div>
          <button (click)="removeHHA('Phone', i)" *ngIf="i > 0">
            <svg
              class="choose-form__delete"
              fill="none"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg">
              <path
                clip-rule="evenodd"
                d="M10 1.93548C5.54609 1.93548 1.93548 5.54609 1.93548 10C1.93548 14.4539 5.54609 18.0645 10 18.0645C14.4539 18.0645 18.0645 14.4539 18.0645 10C18.0645 5.54609 14.4539 1.93548 10 1.93548ZM0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10ZM13.2379 6.76183C13.6158 7.13976 13.6158 7.7525 13.2379 8.13042L11.3686 9.99972L13.2387 11.8699C13.6167 12.2478 13.6167 12.8605 13.2387 13.2385C12.8608 13.6164 12.2481 13.6164 11.8701 13.2385L10 11.3683L8.12847 13.2398C7.75054 13.6178 7.1378 13.6178 6.75988 13.2398C6.38195 12.8619 6.38195 12.2492 6.75988 11.8712L8.6314 9.99972L6.76072 8.12904C6.3828 7.75111 6.3828 7.13837 6.76072 6.76045C7.13865 6.38252 7.75139 6.38252 8.12932 6.76045L10 8.63113L11.8693 6.76183C12.2472 6.3839 12.86 6.3839 13.2379 6.76183Z"
                fill-rule="evenodd"/>
            </svg>
          </button>
        </div>
      </div>
    </div>
    <div class="choose-form__buttons">
      <button (click)="addHHA('Phone')"
              *ngIf="(phoneFormGroup.controls['hhaPhones'] | formControl).controls.length < 20"
              class="add-button">
        <i class="icon-plus"></i> Add one more DCW
      </button>
    </div>
    <div class="">
      <button (click)="sendDocs('phone', true)" [disabled]="!created || !phoneFormGroup.valid || isDisableSendAction"
              class="btn violet">
        Send
      </button>
    </div>

  </div>


  <div *ngIf="errors.length" class="error-list">
    <div *ngFor="let err of errors" class="error">{{ err }}</div>
  </div>

  <div *ngIf="activePhoneField && typeOfApplication !== AdmissionTypeEnum.POC" [class.double]="doubleFields"
       class="phone-form">
    <div class="phone-form__wrapper">
      <ng-container *ngIf="doubleFields">
        <div class="input-wrapper__container">
          <ng-container
            [ngTemplateOutletContext]="{ showLabel: true }"
            [ngTemplateOutlet]="customerPhone">
          </ng-container>
          <ng-container [ngTemplateOutlet]="customerRepPhone"></ng-container>
        </div>
      </ng-container>
      <ng-container *ngIf="!doubleFields">
        <ng-container [ngTemplateOutlet]="customerPhone"></ng-container>
      </ng-container>
      <div class="button-wrapper">
        <button
          (click)="sendDocs('phone')"
          [disabled]="isDisableSendAction || (role === RoleEnum.Employee ? getPhoneSendLength <= 2 : (getPhoneSendLength <= 2 && getPhoneSendRepLength <= 2))"
          class="btn violet send">
          Send
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="activeEmailField && typeOfApplication !== AdmissionTypeEnum.POC" [class.double]="doubleFields"
       class="phone-form">
    <div class="phone-form__wrapper">
      <ng-container *ngIf="doubleFields">
        <div class="input-wrapper__container">
          <ng-container
            [ngTemplateOutletContext]="{ showLabel: true }"
            [ngTemplateOutlet]="customerEmail">
          </ng-container>
          <ng-container [ngTemplateOutlet]="customerRepEmail"></ng-container>
        </div>
      </ng-container>
      <ng-container *ngIf="!doubleFields">
        <ng-container [ngTemplateOutlet]="customerEmail"></ng-container>
      </ng-container>
      <div class="button-wrapper">
        <button
          (click)="sendDocs('email')"
          [disabled]="!created || (!emailSend.length || isDisableSendAction)"
          class="btn violet send">
          Send
        </button>
      </div>
    </div>
  </div>
</div>

<div class="success" style="display: none">
  <div class="success__wrapper">
    <div class="success__header">
      <span class="icon"></span>
    </div>
    <div class="success__body">
      <span>Link successfully sent</span>
    </div>
  </div>
</div>

<ng-template #customerPhone let-showLabel="showLabel">
  <label class="input-wrapper">
    <div *ngIf="showLabel" class="input-wrapper__name">Customer</div>
    <input
      (keyup)="isDisableSendAction = false"
      [(ngModel)]="phoneSend"
      [class.error]="errorSend.smsMobileNumber || errorSend.phone"
      [mask]="phoneMask"
      appMask
      placeholder="+1 (000) 000-0000"
      type="tel"/>
    <span *ngIf="errorSend.smsMobileNumber || errorSend.phone" class="err-msg">
      {{ errorSend.smsMobileNumber || errorSend.phone }}
    </span>
    <span *ngIf="errorSend.documentKeys" class="err-msg"> {{ errorSend.documentKeys }} </span>
  </label>
</ng-template>

<ng-template #customerRepPhone>
  <label class="input-wrapper">
    <div class="input-wrapper__name">Company Representative</div>
    <input
      [(ngModel)]="phoneSendRep"
      [class.error]="errorSend.smsMobileNumber || errorSend.secondPhone"
      [mask]="phoneMask"
      appMask
      placeholder="+1 (000) 000-0000"
      type="tel"/>
    <span *ngIf="errorSend.smsMobileNumber || errorSend.secondPhone" class="err-msg">
      {{ errorSend.smsMobileNumber || errorSend.secondPhone }}
    </span>
    <span *ngIf="errorSend.documentKeys" class="err-msg"> {{ errorSend.documentKeys }} </span>
  </label>
</ng-template>

<ng-template #customerEmail let-showLabel="showLabel">
  <label class="input-wrapper">
    <div *ngIf="showLabel" class="input-wrapper__name">Customer</div>
    <input (ngModelChange)="removeError('email')" [(ngModel)]="emailSend" type="text"/>
    <span *ngIf="errorSend?.email" class="err-msg">{{ errorSend.email }}</span>
    <span *ngIf="errorSend?.documentKeys" class="err-msg"> {{ errorSend.documentKeys }} </span>
  </label>
</ng-template>

<ng-template #customerRepEmail>
  <label class="input-wrapper">
    <div class="input-wrapper__name">Company Representative</div>
    <input (ngModelChange)="removeError('secondEmail')" [(ngModel)]="emailSendRep" type="text"/>
    <span *ngIf="errorSend?.secondEmail" class="err-msg">{{ errorSend.secondEmail }}</span>
    <span *ngIf="errorSend?.documentKeys" class="err-msg"> {{ errorSend.documentKeys }} </span>
  </label>
</ng-template>

<ng-template #loading>
  <div class="overlay">
    <img alt="loading" src="/assets/img/datatable/loading.gif"/>
  </div>
</ng-template>
